import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CookiesPage = () => (
  <Layout>
    <SEO title="Cookies Policy" keywords={[`nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, `election`, `poll`]} />
      <div>
        <h1>Cookies Policy</h1>
        <script
          id="CookieDeclaration"
          src="https://consent.cookiebot.com/4684fbd3-c4b5-4400-b514-077d6a4121af/cd.js"
          type="text/javascript"
          async>
        </script>
      </div>
  </Layout>
)

export default CookiesPage
